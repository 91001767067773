<template>
  <div id="TermPageContainer">
    <h1>Terms of Service</h1>
    <cite class="centered">Effective Date: 2025-02-19</cite>
    <p>
      10-4 ("us", "we", or "our") operates the 10-4 website and the 10-4 discord application (the "Service").

      These terms of service ("Terms") govern your access to and use of the website located at https://10-4.app (the
      "Website") and the services provided by us (the "Services").

      Please read these Terms carefully before accessing or using the Website or Services. By accessing or using the
      Website or Services, you agree to be bound by these Terms. If you do not agree to all of the terms and
      conditions of these Terms, then you may not access or use the Website or Services.

      These Terms apply to all visitors, users and others who access or use the Website or Services by any means,
      including, but not limited to, browsers, devices, applications, and social media platforms.
    </p>

    <h2>Changes to Terms</h2>
    <p>
      We may modify these Terms at any time. The most current version of the Terms will always be at
      https://10-4.app/terms. It is your responsibility to check the Website for changes to these Terms. Your
      continued use of the Website or Services after any changes to these Terms will constitute your acceptance of
      such changes.
    </p>

    <h2>Changes to Website or Services</h2>
    <p>
      We reserve the right to change or discontinue any aspect of the Website or Services at any time, including the
      availability of any feature, database, or content. We may also impose limits on certain features and services or
      restrict your access to parts or all of the Website or Services without notice or liability.
    </p>

    <h2>Access to Website or Services</h2>
    <p>
      We grant you a personal, non-transferable and non-exclusive right and license to use the Website and Services.
      You may not use the Website or Services for any commercial purpose or in any way that is unlawful or harms us or
      any third party. You may not use the Website or Services in any manner that could disable, overburden, damage,
      or impair the Website or Services or interfere with any other party use and enjoyment of the Website or
      Services, unless you have been given written permission to do so by us. You may not obtain or attempt to obtain
      any materials or information through any means not intentionally made available or provided for through the
      Website or Services.
    </p>

    <h2>Intellectual Property</h2>
    <p>
      The Website and Services are protected by copyright, trademark, and other laws of the United States and foreign
      countries. You acknowledge and agree that the Website and Services contain proprietary and confidential
      information that is protected by applicable intellectual property and other laws. Except as expressly authorized
      by us, you agree not to modify, rent, lease, loan, sell, distribute, or create derivative works based on the
      Website or Services, in whole or in part.
    </p>

  </div>
</template>

<script>
export default {
  name: 'TermsPage'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  margin-bottom: 0;
}

.centered {
  justify-content: center;
  display: flex;
}

#TermPageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

p {
  line-height: 24px;
  font-size: 16px;
}
</style>

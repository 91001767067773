import LandingPage from './views/LandingPage.vue';
import { createRouter, createWebHistory } from 'vue-router'
import TermsPage from './views/TermsPage.vue';
import PrivacyPolicyPage from './views/PrivacyPolicyPage.vue';
import DiscordPage from './views/DiscordPage.vue';

const routes = [
    {
        path: '/',
        component: LandingPage,
    },
    {
        path: '/privacy',
        name: 'Privacy Policy',
        component: PrivacyPolicyPage,
    },
    {
        path: '/terms',
        name: 'Terms of Services',
        component: TermsPage,
    },
    {
        path: '/discord',
        name: 'Discord',
        component: DiscordPage,
    },
];


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const title = to.name; // Fallback title if none is provided
    let pageTitle = '10-4';
    if (title != undefined) {
        pageTitle += ' - ' + title;
    }
    document.title = pageTitle;
    next();
});

export default router
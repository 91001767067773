<script setup>
const discordServers = [
  {
    id: 1,
    name: "10-4",
    url: "https://discord.gg/cQ3ypaWG2Q"
  },
  {
    id: 2,
    name: "10-4 Gaming",
    url: "https://discord.gg/hHM4K53NdF"
  }
];
</script>

<script>
export default {
  name: 'DiscordPage',
  methods: {
    navigateToServer(url) {
      window.open(url, '_blank');
    }
  }
}
</script>

<template>
  <div class="discord-container">
    <h1>Join Discord Communities</h1>
    <div class="server-grid">
      <div v-for="server in discordServers" :key="server.id" class="server-bubble"
        @click="navigateToServer(server.url)">
        <div class="bubble-content">
          <span class="server-name">{{ server.name }}</span>
          <span class="join-text">Join the Server</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.discord-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.server-grid {
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 2rem;
}

.server-bubble {
  --color-server-bubble: var(--secondary);
  position: relative;
  background-color: var(--color-server-bubble);
  /* Discord blue */
  border-radius: 50%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 150px;
  height: 150px;
}

.server-bubble:hover {
  transform: scale(1.05);
  background-color: var(--primary);
}

.bubble-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
  text-align: center;
}

.server-name {
  font-weight: bold;
  color: white;
  font-size: 1.2rem;
  transition: opacity 0.3s ease;
}

.join-text {
  position: absolute;
  color: white;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.server-bubble:hover .server-name {
  opacity: 0.2;
  filter: blur(2px);
}

.server-bubble:hover .join-text {
  opacity: 1;
}
</style>
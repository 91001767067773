<template>
  <footer>
    <div v-for="menu in menus" :key="menu.title" class="footer-menu-link" @click="navigateTo(menu.href)">
      <a>{{ menu.title }}</a>
    </div>
  </footer>
</template>

<script setup>
const menus = [
  {
    title: 'Discord Links ',
    href: '/discord'
  },
  {
    title: 'Terms',
    href: '/terms'
  },
  {
    title: 'Policy',
    href: '/privacy'
  }
]
</script>

<script>
export default {
  name: 'FooterBar',
  methods: {
    navigateTo(routerPath) {
      this.$router.push(routerPath);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
footer {
  --footer-height: 40px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  min-height: var(--footer-height);
}

.bot-name {
  font-size: 18px;
  display: flex;
  align-items: center;

  svg {
    padding-right: 10px;
  }

  :hover {
    cursor: pointer;
  }
}

.navbar {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-menu-link {
  min-width: 100px;
  text-align: center;
}
</style>

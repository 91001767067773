<template>
  <div id="header">
    <nav class="navbar">
      <span class="bot-name" @click="navigateToHome">
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none"
          stroke="var(--primary)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
          class="lucide lucide-bot-icon w-8 h-8 text-red-500">
          <path d="M12 8V4H8"></path>
          <rect width="16" height="12" x="4" y="8" rx="2"></rect>
          <path d="M2 14h2"></path>
          <path d="M20 14h2"></path>
          <path d="M15 13v2"></path>
          <path d="M9 13v2"></path>
        </svg>
        <span>10-4</span>
      </span>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'HeaderBar',
  methods: {
    navigateToHome() {
      this.$router.push('/');
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#header {
  position: sticky;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.5px);
  padding-right: 8px;
  padding-left: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 5;
}

.bot-name {
  font-size: 18px;
  display: flex;
  align-items: center;

  svg {
    padding-right: 10px;
  }

  :hover {
    cursor: pointer;
  }
}

.navbar {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>

<template>
  <div class="container">
    <section class="hero">
      <h1>Your Ultimate Discord Companion</h1>
      <p>Enhance your server with moderation, fun games and chat management</p>
      <div class="button-group">
        <ButtonComponent content="Community Server" @click="openDiscordPage" />
        <ButtonComponent content="Add to discord" type="primary" @click="openDiscordLink"
          iconPath="assets/icons/discord.svg" />
      </div>
    </section>

    <section class="features">
      <div v-for="feature in features" :key="feature.title" class="feature-card">
        <div class="feature-icon">{{ feature.icon }}</div>
        <h3>{{ feature.title }}</h3>
        <p>{{ feature.description }}</p>
      </div>
    </section>
  </div>
</template>

<script setup>

const features = [
  {
    icon: '🗿',
    title: 'Effortless Server Management',
    description: 'Manage your server with ease using powerful moderation tools that ensure smooth operations, including automatic base roles for new players.'
  },
  {
    icon: '⚡',
    title: 'Lightning-Fast Performance',
    description: 'Experience blazing-fast response times and 99.9% uptime for a seamless experience.'
  }
]
</script>

<script>
import ButtonComponent from '../components/ButtonComponent.vue';

export default {
  name: 'LandingPage',
  components: {
    ButtonComponent
  },
  methods: {
    openDiscordLink() {
      window.open('https://discord.com/oauth2/authorize?client_id=715682329149964401', '_blank');
    },

    openDiscordPage() {
      this.$router.push('/discord');
    }
  }
}
</script>

<style scoped>
.hero {
  text-align: center;
  padding: 4rem 0;
}

.hero h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, var(--primary), #ff8f9c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #a1a1aa;
}

.button-group {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 4rem 0;
}

.feature-card {
  background-color: var(--card-bg);
  padding: 2rem;
  border-radius: 1rem;
  text-align: left;
}

.feature-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--primary);
}

.feature-card h3 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.feature-card p {
  margin: 0;
  color: #a1a1aa;
}

.stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  padding: 4rem 0;
  text-align: center;
}

.stat-item h2 {
  font-size: 3rem;
  margin: 0;
  background: linear-gradient(45deg, var(--primary), #ff8f9c);
  -webkit-text-fill-color: transparent;
}

.stat-item p {
  margin: 0;
  color: #a1a1aa;
}
</style>

<template>
  <div id="PrivacyPageContainer">
    <h1>Privacy Policy</h1>
    <cite class="centered">Effective Date: 2025-02-21</cite>
    <p>
      We take your privacy seriously. This privacy policy describes what personal information we collect and how we
      use it.

      We do not sell your data to third parties. We do not collect any data that could directly be used to identify
      you.
    </p>

    <h2>Data Collected</h2>
    <p>
      Here is a list of all data that we store when you use 10-4:
    </p>
    <ul>
      <li>Discord account information: We store your Discord Account ID, so that we can store user preferences.</li>
      <li>No other Discord account information is stored at this time.</li>
    </ul>

    <h2>Data Storage Duration</h2>
    <p>
      We store your data for as long as you are using the Service or the Website. You can always contact us on <a
        @click="openCommunityServerLink">our Discord server</a> to request the deletion of your data.
    </p>

  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  "methods": {
    openCommunityServerLink() {
      window.open('https://discord.gg/cQ3ypaWG2Q', '_blank');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  margin-bottom: 0;
}

.centered {
  justify-content: center;
  display: flex;
}

#PrivacyPageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

p,
cite,
h2 {
  color: white;

}

p {
  line-height: 24px;
  font-size: 16px;
}
</style>

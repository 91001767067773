<template>
  <button class="btn" :class="type == 'primary' ? 'btn-primary' : 'btn-default'">
    <div v-if="iconPath" class="icon"><img :src="iconPath" alt="Icon" /></div>
    {{ content }}
  </button>
</template>

<script>
export default {
  name: 'ButtonComponent',
  props: {
    type: {
      type: String,
      default: 'default'
    },
    content: {
      type: String,
      required: true
    },
    iconPath: {
      type: String,
      default: '' // Path to the SVG file
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.icon {
  width: 20px;
  height: 20px;
  margin-right: .5rem;
}

.btn {
  display: flex;
  align-items: center;
  height: 48px;

  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  transition: background-color 0.2s;

  &.btn-primary {
    background-color: var(--primary);

  }

  &.btn-primary:hover {
    background-color: var(--primary-dark);
  }

  &.btn-default {
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.1);

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}
</style>

<template>
  <HeaderBar />
  <router-view id="workzone"></router-view>
  <FooterBar />
</template>

<script>
import FooterBar from './components/FooterBar.vue';
import HeaderBar from './components/HeaderBar.vue'

export default {
  name: 'App',
  components: {
    HeaderBar,
    FooterBar
  }
}
</script>
<style>
:root {
  --primary-dark: #c23857;
  --primary: #d93f63;
  --card-bg: #242f41;
  --secondary: var(--card-bg);
  --text: rgba(255, 255, 255, 0.87);
  --background: #1a1f2e;
  --color-link: rgb(107, 107, 107);
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color: var(--text);
  background-color: var(--background);
}

body,
html,
#app {
  margin: 0;
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: var(--color-link);
}

h1 {
  margin-bottom: 2rem;
  text-align: center;
}

#workzone {
  flex: 1;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  margin-bottom: 2rem;
}

#app {
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>
